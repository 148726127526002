import React from "react"
import { StaticQuery, graphql } from "gatsby"
// import {FaEnvelope, FaLocationArrow, FaFacebook, FaLinkedin} from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import SiteImg1 from "../assets/img/component-site.png"



const ComplaintsProcedure = () => (
  <StaticQuery
    query={graphql`
      query{
        allStrapiPages{
          edges {
            node {
              PageTitle
            }
          }
        }
      }
    `}

    render={data => (
      
    <Layout pageData={""}>
        <SEO title="Complaints Procedure" />


        <section className="contact-form-section">

          <h1>Complaints Handling Procedure</h1>

          <div className="full-width-paragraph">
            				
            			
            <p>Our Promise</p>
            <p>Our complaints procedure is designed to ensure that each complaint is dealt with fairly and promptly.</p>
            <p>We will endeavour, wherever possible, to resolve any complaint to your satisfaction at the time we are made aware. If we are unable to resolve your complaint straight away, we will send you an acknowledgement letter within 2 working days of the complaint being received.</p>
            <p>We are committed to resolving complaint as soon as possible and in any case, if after a 4-week period we are still not in a position to respond to you with either:<br/>
                a. A final response, or<br/>
                b. A holding response, which will explain why we are not in a position to resolve the complaint. We will also inform you when we make further contact regarding your complaint.<br/>
            In any case, after an 8-week period after receipt of your complaint, we will write to you with either:</p>
            <p>   a. A final response<br/>
                b. A response which<br/>
                    i. Explains that we are still not in a position to give you a final response. We will also give you time frames as to when we feel we will be able to provide a response; and<br/>
                    ii. Informs you of your right to refer your complaint to the Financial Ombudsman:</p>
            <p>Claims Management Ombudsman
Exchange Tower <br/>
London <br/>
E 14 9 SR <br/>
Tel: 0800 023 4567<br/>
Web: https://cmc.financial-ombudsman.org.uk/</p>
            <p>The Financial Ombudsman can investigate complaints up to six years from the date of the problem happening or within three years of when you found out about the problem. If you wish to refer your complaint to the Financial Ombudsman this must be done within six months of our final response to your complaint.<br/>
            If your complaint is resolved within 3 days, there is no requirement to escalate your complaint further.</p>
            <p>We are Authorised and regulated by the Financial Conduct Authority (938168)</p>
            <p>Contact Information – SEO Developments Limited</p>
            <p>Email: <a href="mailto:info@seodevelopments.co.uk">info@seodevelopments.co.uk</a></p>
            <p>Address: 42 Folkestone Road, Southport, England, PR8 5PH</p>
            <p>Tel: <a href="tel:03301343283">0330 134 3283</a> </p>
            <p>Company Number: 12902071</p>
    
 

          </div>
          
        </section>


      </Layout>

    )}
  
  />
)

export default ComplaintsProcedure
